import React from 'react'
import PropTypes from 'prop-types'
import kebabCase from 'lodash/kebabCase'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import styled from 'styled-components'

const TagsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin-right: 10px;
    line-height: 1.5;

    a {
      color: ${props => props.theme.linkColor};
    }
  }
`

export default function TagsPage(
  {
    data: {
      allMdx: { group },
      site: {
        siteMetadata: { title },
      },
    },
  },
  location
) {
  return (
    <Layout location={location} title={title}>
      <SEO title="Tags" keywords={[`blog`, `python`, `javascript`, `react`]} />
      <h1>Tags</h1>
      <TagsList>
        {group.map(tag => (
          <li key={tag.fieldValue}>
            <Link to={`/kb/tags/${kebabCase(tag.fieldValue)}/`}>
              #{tag.fieldValue}
            </Link>
          </li>
        ))}
      </TagsList>
    </Layout>
  )
}

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(filter: { fields: { collection: { eq: "kb" } } }, limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
